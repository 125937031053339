<template>
  <div>
    <b-modal size="lg" hide-footer v-model="modalRelatedDocuments" class="w-100" title="Agregar Documentos Relacionados">
      <b-form id="Form" autocomplete="off" @submit.prevent="Validate">
        <b-row>
          <b-col md="4">
            <b-form-group label="Tipo Comprobante">
              <b-form-select :options="type_invoice" v-model="related_document.type_invoice"></b-form-select>
              <small v-if="errors.type_invoice"  class="form-text text-danger" >Seleccione un comprobante</small>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="Serie:">
              <b-form-input v-model="related_document.serie"></b-form-input>
              <small v-if="errors.serie"  class="form-text text-danger" >Ingrese una serie</small>
            </b-form-group>
          </b-col>
       
          <b-col md="3">
            <b-form-group label="Numero:">
              <b-form-input @change="NumberPadStart" v-model="related_document.number"></b-form-input>
              <small v-if="errors.number"  class="form-text text-danger" >Ingrese un numero</small>
            </b-form-group>
          </b-col>
        

          <b-col md="2">
            <b-form-group label=".">
                <b-button class="form-control" type="submit" variant="info" title="Modificar"><i class="fas fa-save"></i></b-button>
              </b-form-group>
          </b-col>
        </b-row>
      </b-form>
      
    </b-modal>
  </div>
</template>
<style>



</style>
<script>
const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
import { mapState,mapActions } from "vuex";
import EventBus from "@/assets/js/EventBus";



export default {
  name: "ModalsProduct",
  data() {
    return {
        modalRelatedDocuments:false,
        module:'ReferralGuide',
        role:0,
        related_document:{
          type_invoice:'01',
          serie:'',
          number:'',
        },
         type_invoice:[
          {value: "01", text : "Factura"},
          {value: "03", text : "Boleta de Venta"},
          {value: "04", text : "Liquidación de compra"},
          {value: "12", text : "Ticket o cinta emitido por máquina registradora"},
        ],
        errors:{
          type_invoice:false,
          serie:false,
          number:false,
        },
    };
  },
  created (){
    
  },
  mounted () {
    EventBus.$on('ModalRelatedDocumentsShow', (role) => {
      this.modalRelatedDocuments = true;
      this.role = role;
    });
    
  },
  methods: {
      Validate,
      NumberPadStart,
  },
  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
  },
};

function NumberPadStart() {
  if (this.related_document.number.length > 0) {
     var number = String(this.related_document.number);
      number = number.padStart(8,"0");
      this.related_document.number = number;
  }
}

function Validate() {

  if (this.related_document.type_invoice.length == 0) {
    Swal.fire({ icon: 'error', text: 'Seleccione un tipo de comprobante', timer: 3000,})
    return false;
  }
  if (this.related_document.serie.length != 4) {
    Swal.fire({ icon: 'error', text: 'Ingrese una serie de 4 digitos', timer: 3000,})
    return false;
  }
  if (this.related_document.number.length != 8) {
    Swal.fire({ icon: 'error', text: 'Ingrese un numero de 8 digitos', timer: 3000,})
    return false;
  }
  
  let data = {
    type_invoice: this.related_document.type_invoice,
    serie: this.related_document.serie,
    number: this.related_document.number,
  }
  EventBus.$emit('AddRelatedDocuments',data);

  this.related_document.type_invoice = "01";
  this.related_document.serie = "";
  this.related_document.number = "";

  this.modalRelatedDocuments = false;

}



</script>
